import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import { Grid, Row, Col } from 'react-flexbox-grid';

import imgSEO from '../../../assets/img/services/referencement.jpg';
import SimilarCard from '../../../components/similar.card';
import similar2 from '../../../assets/img/heroes/thumbnails/contenu.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/optimisation.jpg';
import similar4 from '../../../assets/img/heroes/thumbnails/social2.jpg';
import SEO from '../../../components/seo';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/search.svg';
import contentPic from '../../../assets/img/illustrations/search_engines.svg';
import contentPic2 from '../../../assets/img/illustrations/invest.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Référencement  | Agence digitale | MDS Digital Agency"
      description="MDS Digital Agency est une agence digitale spécialisée dans le référencement naturel (SEO) et le référencement payant (SEA). Nous utilisons Google Ads pour la création de vos campagnes."
      keywords="Référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Référencement</h2>
      <div className={pageStyle.intro_container} style={{ marginTop: '3em', marginBottom: '6em' }}>
        <h4 className={pageStyle.intro}>
          Augmenter la visibilité de votre entreprise sur les moteurs de recherche.{' '}
        </h4>
      </div>
      <Grid style={{ marginBottom: '6em', textAlign: 'right' }}>
        <Row>
          {' '}
          <Col md={12} style={{ textAlign: 'left' }}>
            {' '}
            <h2 id="sur-mesure" style={{ textAlign: 'left' }} className={pageStyle.subtitle}>
              Référencement naturel
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={8} className={pageStyle.flex_v_align}>
            <div>
              <p>
                Le référencement naturel consiste à adapter votre site internet pour répondre aux
                critères évolutifs établis par Google afin de le positionner en première page des
                résultats de recherche.
              </p>
              <p>
                Contrairement au référencement payant (SEA), le référencement naturel ne s’achète
                pas. Il se construit en établissant une stratégie d’optimisation de différents
                paramètres de votre site web.
              </p>
              <p>
                MDS Digital Agency met en place une stratégie SEO adaptée à votre entreprise. Nous
                optimisons le contenu avec un service de copywriting, les backs links mais aussi
                votre site de manière technique.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <img
              src={contentPic}
              alt=""
              style={{
                display: 'inline-block',
                marginTop: '15px'
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '40px' }}>
          <Col md={12}>
            <h2 id="sur-mesure" className={pageStyle.subtitle} style={{ textAlign: 'right' }}>
              Référencement payant
            </h2>
          </Col>
          <Col md={4} className={pageStyle.flex_v_align}>
            <img
              src={contentPic2}
              alt=""
              style={{
                display: 'inline-block',
                marginRight: '30px '
              }}
            />
          </Col>
          <Col md={8} className={pageStyle.flex_v_align}>
            <div>
              <p>
                Les campagnes Google Ads permettent en effet de faire apparaître rapidement une
                annonce dans les premiers résultats de Google en fonction de mots clés prédéfinis.
              </p>
              <p>
                Les campagnes SEA fonctionnent sur le principe de coût par clic. Concrètement Google
                facture en fonction du nombre de personnes qui ont cliqué sur votre annonce. Ce
                système d’affichage d’annonces peut vite devenir onéreux et contre-productif si on
                s’y lance sans connaissance.
              </p>
              <p>
                MDS Digital Agency est spécialisé dans la création de campagne Google Ads. De la
                recherche de mots-clés à l’écriture des annonces, nous nous chargeons de tout pour
                vous garantir une campagne Google Ads avec de vrais résultats.
              </p>
            </div>
          </Col>
        </Row>
      </Grid>

      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="A la recherche d’une stratégie de référencement sur mesure ?"
        subtitle="Bénéficiez d’une plus grande visibilité sur le web grâce à notre forte expertise."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Gestion de contenu"
            url="/services/website/gestion-de-contenu/"
            img={similar2}
          />
          <SimilarCard
            text="Optimisation de site web"
            url="/services/website/optimisation/"
            img={similar3}
          />
          <SimilarCard text="Réseaux sociaux" url="/services/reseaux-sociaux/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
